"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.CreateSchool = function CreateSchool() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  var count = 0;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var selectReg = $("#regions");
    var selectComm = $("#commScol");
    var selectEcole = $("#ecole");
    var currentForm = $(".site-form");
    var inputMail = $("#email");
    var inputUsername = $("#username");

    if (inputMail) {
      inputMail.change(function () {
        inputUsername.val(inputMail.val());
      });
    }

    if (selectReg) {
      selectReg.change(function () {

        var deletedOptions = selectComm.find('option:not(:first)');

        $.each(deletedOptions, function (i, item) {
          item.remove();
        });

        var deletedOptionsEcole = selectEcole.find('option:not(:first)');

        $.each(deletedOptionsEcole, function (i, item) {
          item.remove();
        });

        var optionSelected = $("option:selected", this);
        var valueSelected = this.value;

        $.ajax({
          type: "get",
          url: '/actions/site-module/default/get-region?regId=' + valueSelected,
          success: function success(response) {
            console.log(response);

            var data = JSON.parse(response);

            $.each(data, function (i, item) {
              selectComm.append('<option value="' + item.id + '">' + item.title.replace(/ \(\d+\)/g, "") + '</option>');
            });

            $('.commScol').css('display', 'block');
          },
          error: function error(error) {
            console.log(error);
            alert('error');
          }
        });
      });
    }

    if (selectComm) {
      selectComm.change(function () {

        var deletedOptionsEcole = selectEcole.find('option:not(:first)');

        $.each(deletedOptionsEcole, function (i, item) {
          item.remove();
        });

        var optionSelected = $("option:selected", this);
        var valueSelected = this.value;

        $.ajax({
          type: "get",
          url: '/actions/site-module/default/get-comm?commId=' + valueSelected,
          success: function success(response) {
            console.log(response);

            var data = JSON.parse(response);

            $.each(data, function (i, item) {
              selectEcole.append('<option value="' + item.id + '">' + item.title.replace(/ \(\d+\)/g, "") + '</option>');
            });

            $('.eco').css('display', 'block');
          },
          error: function error(error) {
            console.log(error);
            alert('error');
          }
        });
      });
    }

    // if (currentForm) {
    //
    //   $(".saveEcole").click(function (e) {
    //     e.preventDefault();
    //
    //     var form = currentForm;
    //
    //     var url = 'users/save-user';
    //
    //
    //     var formData = new FormData(currentForm[0]);
    //     console.log(formData);
    //
    //     $.ajax({
    //       type: "POST",
    //       url: url,
    //       data: formData,
    //       processData: false,
    //       contentType: false,
    //       success: function success() {
    //         alert('success');
    //       },
    //       error: function error(error) {
    //         alert('error');
    //         console.log(error);
    //         // swal({
    //         //   title: 'Les informations ont bien été sauvegardées.',
    //         //   icon: "success",
    //         //   button: 'Continuer'
    //         // });
    //       }
    //     });
    //   });
    // }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();